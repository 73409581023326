import React, { useEffect, useState, useCallback } from 'react';
import { Timeline } from 'antd';
import { throttle } from 'lodash';
import '../../style.scoped.less';
import dayjs from 'dayjs';

const MyTimeline = props => {
  const { timeRangeTypeList = [], data = [], fullScreen } = props;
  // const [items, setItems] = useState([]);
  const [activeMonth, setActiveMonth] = useState(null);
  const [activeYear, setActiveYear] = useState(null);
  const [dealData, setDealData] = useState([]);

  // 处理滚动逻辑
  const handleScrollLogic = useCallback(() => {
    const flowContainer = document.querySelector('.pagelet-personal-didi-flow');
    if (!flowContainer || !data.length) return;

    const currentIndex = +Math.ceil(
      (document.querySelector('.pagelet-personal-didi-flow').scrollTop - 60) / 156,
    );
    const currentItem = data[currentIndex > 0 ? currentIndex : 0];
    // 如果只有data长度只有三个，需要判断timeRangeTypeList的每项的list的长度加一起少于3个的时候，把activeYear设置为timeRangeTypeList的第一项的code, activeMonth设置为timeRangeTypeList的第一项的list的第一个元素的month
    if (data.length === 3 && timeRangeTypeList.length) {
      const sum = timeRangeTypeList.reduce((acc, cur) => {
        return acc + cur.list.length;
      }, 0);
      if (sum < 3) {
        setActiveYear(timeRangeTypeList[0].year);
        setActiveMonth(timeRangeTypeList[0].list[0].month);
      }
    } else {
      setActiveYear(dayjs(currentItem.effectDate).year() + '');
      setActiveMonth(dayjs(currentItem.effectDate).month() + 1 + '');
    }

    // const scrollTop = flowContainer.scrollTop;
    // const containerHeight = flowContainer.clientHeight;

    // let foundActiveYear = null;
    // let foundActiveMonth = null;

    // // 遍历所有年份和月份元素
    // for (const yearData of timeRangeTypeList) {
    //   for (const monthData of yearData.list) {
    //     const month = monthData.month < 10 ? '0' + monthData.month : monthData.month;
    //     const monthElement = document.getElementById(`key-${yearData.year}-${month}`);

    //     if (monthElement) {
    //       // 获取元素相对于容器的位置
    //       const elementTop = monthElement.getBoundingClientRect().top - flowContainer.getBoundingClientRect().top;
    //       // 如果元素在可视区域内
    //       // if (elementTop >= scrollTop && elementTop <= scrollTop + containerHeight) {
    //         if (elementTop >= 0) {
    //         foundActiveYear = yearData.year;
    //         foundActiveMonth = monthData.month;
    //         break;
    //       }
    //     }
    //   }
    //   if (foundActiveYear) break;
    // }

    // // 更新状态
    // if (foundActiveYear) {
    //   setActiveYear(foundActiveYear);
    //   setExpandedYear(foundActiveYear);
    //   setActiveMonth(foundActiveMonth);
    // }
  }, [timeRangeTypeList, data]);

  // 使用 lodash 的 throttle 创建节流函数
  const throttledScrollHandler = useCallback(
    throttle(handleScrollLogic, 200, { leading: true, trailing: true }),
    [handleScrollLogic],
  );

  // 监听滚动，更新当前激活的年份和月份
  useEffect(() => {
    console.log('useEffect running');
    const flowContainer = document.querySelector('.pagelet-personal-didi-flow');

    if (flowContainer) {
      flowContainer.addEventListener('scroll', throttledScrollHandler);
      // 初始化执行一次
      handleScrollLogic();
    }

    return () => {
      if (flowContainer) {
        flowContainer.removeEventListener('scroll', throttledScrollHandler);
        // 取消还未执行的节流函数
        throttledScrollHandler.cancel();
      }
    };
  }, [handleScrollLogic, throttledScrollHandler]);

  const scrollToMonth = (year, month) => {
    month = month < 10 ? '0' + month : month;
    const element = document.querySelector(`.key-${year}-${month}`);
    if (element) {
      // setTimeout(() => {
      element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      const container = document.querySelector('#dhr-my-grid-container');
      const outerContainer = document.querySelector('.dihr-card-warper-panel');
      if (fullScreen && container && outerContainer) {
        container.scrollTop = 0;
        outerContainer.scrollTop = 0;
      }
      // }, 300);
    }
  };

  useEffect(() => {
    const timeRange = timeRangeTypeList.map(item => {
      item.isHasKey = item.list.some(monthList => {
        return monthList.hotList?.some(hot => !!hot);
      });
      return item;
    });
    setDealData(timeRange);
  }, [timeRangeTypeList]);

  // useEffect(() => {
  //   const ii = dealData.map(item => {
  //     const isActiveYear = activeYear === item.year;
  //     return {
  //       color: isActiveYear ? '#FF6400' : '#DDDDDD',
  //       children: (
  //         <>
  //           <div
  //             onClick={() => {
  //               setActiveYear(item.year);
  //               setActiveMonth(item.list[0].month);
  //               scrollToMonth(item.year, item.list[0].month);
  //             }}
  //             className={`year-item ${isActiveYear ? 'expanded' : ''} ${isActiveYear ? 'active' : ''}`}
  //             style={{
  //               cursor: 'pointer',
  //               padding: '0px 2px',
  //               background: isActiveYear ? 'rgba(255, 109, 63, 0.1)' : 'transparent',
  //               borderRadius: '4px',
  //               display: 'inline-block',
  //               transition: 'all 0.3s',
  //               fontFamily: 'PingFang SC',
  //               fontSize: '10px',
  //               fontWeight: '500',
  //               lineHeight: '16px',
  //               height: '16px',
  //               // color: #FF6400;
  //             }}
  //           >
  //             {item.year}
  //             {item.isHasKey && (
  //               <img
  //                 src="//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/star.svg"
  //                 width={8}
  //                 height={8}
  //                 style={{
  //                   display: 'inline-block',
  //                   marginLeft: '2px',
  //                   position: 'relative',
  //                   top: '-1px',
  //                 }}
  //               />
  //             )}
  //           </div>
  //           <ul
  //             style={{
  //               height: isActiveYear ? 'auto' : '0',
  //               overflow: 'hidden',
  //               transition: 'all 0.3s',
  //               opacity: isActiveYear ? 1 : 0,
  //             }}
  //           >
  //             {item.list.map(monthItem => {
  //               const isActive = activeMonth === monthItem.month && activeYear === item.year;
  //               return (
  //                 <li
  //                   key={monthItem.month}
  //                   onClick={e => {
  //                     e.stopPropagation();
  //                     setActiveMonth(monthItem.month);
  //                     setActiveYear(item.year);
  //                     scrollToMonth(item.year, monthItem.month);
  //                   }}
  //                 >
  //                   <span
  //                     style={{
  //                       color: isActive ? '#FF6400' : 'rgba(0, 0, 0, 0.65)',
  //                     }}
  //                   >
  //                     {monthItem.month}月
  //                   </span>
  //                   {monthItem.hotList
  //                     .filter(item => Boolean(item))
  //                     .map((hot, index) => {
  //                       return (
  //                         <span
  //                           key={index}
  //                           style={{
  //                             // padding: '2px 8px',
  //                             background: '#FF6400',
  //                             borderRadius: '12px',
  //                             color: '#FFFFFF',
  //                             fontSize: '9px',
  //                             display: 'inline-block',
  //                             width: '12px',
  //                             height: '12px',
  //                             textAlign: 'center',
  //                             lineHeight: '12px',
  //                             marginLeft: '2px',
  //                           }}
  //                         >
  //                           {hot}
  //                         </span>
  //                       );
  //                     })}
  //                 </li>
  //               );
  //             })}
  //           </ul>
  //         </>
  //       ),
  //     };
  //   });
  //   setItems(ii);
  // }, [dealData, activeMonth, activeYear]);

  return (
    <>
      <Timeline
        className="pagelet-personal-didi-timeline"
        items={dealData.map(item => {
          const isActiveYear = activeYear === item.year;
          return {
            color: isActiveYear ? '#FF6400' : 'rgba(0, 0, 0, 0.32)',
            style: {
              paddingBottom: isActiveYear ? '4px' : '9px',
            },
            children: (
              <>
                <div
                  onClick={() => {
                    setActiveYear(item.year);
                    setActiveMonth(item.list[0].month);
                    scrollToMonth(item.year, item.list[0].month);
                  }}
                  className={`year-item ${isActiveYear ? 'expanded' : ''} ${isActiveYear ? 'active' : ''}`}
                  style={{
                    cursor: 'pointer',
                    padding: '0px 2px',
                    background: isActiveYear ? 'rgba(255, 109, 63, 0.1)' : 'transparent',
                    borderRadius: '4px',
                    display: 'inline-block',
                    transition: 'all 0.3s',
                    fontFamily: 'PingFang SC',
                    fontSize: '10px',
                    fontWeight: '500',
                    lineHeight: '16px',
                    height: '16px',
                    // color: #FF6400;
                  }}
                >
                  <span style={{ letterSpacing: '1px' }}>{item.year}</span>
                  {item.isHasKey && (
                    // {true && (
                    <img
                      src="//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/star.svg"
                      width={8}
                      height={8}
                      style={{
                        display: 'inline-block',
                        marginLeft: '2px',
                        width: '10px',
                        height: '10px',
                        position: 'relative',
                        top: '-1px',
                      }}
                    />
                  )}
                </div>
                <ul
                  style={{
                    height: isActiveYear ? 'auto' : '0',
                    overflow: 'hidden',
                    transition: 'all 0.3s',
                    opacity: isActiveYear ? 1 : 0,
                  }}
                >
                  {item.list.map(monthItem => {
                    const isActive = activeMonth === monthItem.month && activeYear === item.year;
                    return (
                      <li
                        key={monthItem.month}
                        onClick={e => {
                          e.stopPropagation();
                          setActiveMonth(monthItem.month);
                          setActiveYear(item.year);
                          scrollToMonth(item.year, monthItem.month);
                        }}
                      >
                        <span
                          style={{
                            color: isActive ? '#FF6400' : 'rgba(0, 0, 0, 0.65)',
                            letterSpacing: '1px',
                          }}
                        >
                          {monthItem.month}月
                        </span>
                        {monthItem.hotList
                          .filter(item => Boolean(item))
                          .map((hot, index) => {
                            return (
                              <span
                                key={index}
                                style={{
                                  // padding: '2px 8px',
                                  background: '#FF6400',
                                  borderRadius: '50%',
                                  color: '#FFFFFF',
                                  fontSize: '9px',
                                  display: 'inline-block',
                                  width: '13px',
                                  height: '13px',
                                  textAlign: 'center',
                                  lineHeight: '13px',
                                  marginLeft: '2px',
                                  paddingLeft: monthItem.month > 9 ? '0' : '1px',
                                  letterSpacing: '0',
                                }}
                              >
                                {hot}
                              </span>
                            );
                          })}
                      </li>
                    );
                  })}
                </ul>
              </>
            ),
          };
        })}
      />
    </>
  );
};

export default MyTimeline;
