import React, { useEffect, useState } from 'react';
import { Modal, Spin } from '@didi/ec-base';
import { DHRTable } from '@didi/ec-ehr-base';
import { ArrowRightOutlined, ArrowDotOutlined } from '@didi/iconflow/ec';
import {
  DETAIL_TYPE_LIST,
  DETAIL_TYPE_FORM,
  DETAIL_TYPE_FORM_MAPPING,
  showEndWord,
  leaveType,
  getJobDesc,
  getDateShow,
} from './contant.js';
import './index.less';
function DetailModal(props) {
  const { detailVisible, detialContent, onClose, providerLocale, prefixCls } = props;
  const [type, setType] = useState('');
  const [fromData, setFromData] = useState([]);
  const [ListData, setListData] = useState([]);
  const [pieceData, setPieceData] = useState([]);
  //面试官、内推是表格；除组织任命appoint，其余都是表单
  const listType = ['interview', 'recommend'];
  // situationCode场景code为以下，则从descriptionListMapVO取值；
  const descriptionMapVO = [
    'promotion',
    'transfer',
    'selftransfer',
    'recommend',
    'interview',
    'badge',
    'appoint',
  ];
  // 调动、活水、晋升表单格式为前>后, 勋章需要特殊处理,生日周年样式需要单独处理
  const formTypeList = [
    'promotion',
    'transfer',
    'selftransfer',
    'badge',
    'birthday',
    'anniversary',
  ];
  // 产假、陪产假、婚假、育儿假需要转换下假期类型code为文字
  const leaveTypeList = ['leave_paternity', 'leave_marriage', 'leave_maternity', 'leave_birth'];
  // 时间格式转换
  const timeConversionList = [
    'lastHireDate',
    'positiveDate',
    'contractBegin',
    'contPlanEndDt',
    'terminationDate',
    'beginDate',
    'endDate',
    'birthday',
    'anniversaryDate',
  ];
  useEffect(() => {
    if (Object.keys(detialContent).length === 0) {
      return;
    }
    const situationData = descriptionMapVO.includes(detialContent?.situationCode)
      ? detialContent?.descriptionListMapVO
      : detialContent?.descriptionMapVO;
    if (listType.includes(detialContent?.situationCode)) {
      setType('list');
      setListData(situationData);
    } else if (detialContent?.situationCode === 'appoint') {
      setType('piece');
      setPieceData(situationData);
    } else {
      setType('form');
      let fromDataInfo = [];
      if (!formTypeList.includes(detialContent?.situationCode)) {
        if (!Array.isArray(situationData)) {
          // 当弹窗明细为入职、二次入职、离职、试用期特殊处理序列的展示
          if (
            detialContent.situationCode === 'onboarding' ||
            detialContent.situationCode === 'rehire' ||
            detialContent.situationCode === 'resign' ||
            detialContent.situationCode === 'trail'
          ) {
            DETAIL_TYPE_FORM_MAPPING[detialContent.situationCode].forEach((value, key) => {
              fromDataInfo.push({
                label: value,
                value:
                  key === 'jobDescMontage'
                    ? getJobDesc(situationData)
                    : timeConversionList.includes(key)
                      ? getDateShow(situationData[key])
                      : situationData[key],
              });
            });
          } else {
            DETAIL_TYPE_FORM_MAPPING[detialContent.situationCode].forEach((value, key) => {
              fromDataInfo.push({
                label: value,
                value: timeConversionList.includes(key)
                  ? key === 'contPlanEndDt' && situationData[key] === '9999-12-31'
                    ? '无固定期限'
                    : getDateShow(situationData[key])
                  : situationData[key],
              });
            });
          }
        } else {
          fromDataInfo = situationData;
        }
      } else {
        fromDataInfo = !Array.isArray(situationData) ? [situationData] : situationData;
      }
      setFromData(fromDataInfo);
    }
  }, [detialContent]);
  return (
    <>
      {Object.keys(detialContent).length > 0 && (
        <Modal
          title={
            detialContent?.situationCode === 'badge' ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={fromData[0]?.medalIcon}
                  style={{ width: '18px', height: '18px', lineHeight: '18px', marginRight: '4px' }}
                />
                {detialContent?.title}
              </div>
            ) : (
              <div>{detialContent?.title}</div>
            )
          }
          open={detailVisible}
          footer={null}
          onCancel={() => {
            setFromData([]);
            setListData([]);
            setPieceData([]);
            onClose();
          }}
          width={type === 'list' ? 800 : 600}
          wrapClassName={`${type === 'list' ? `${prefixCls}-detail-modal-list` : `${prefixCls}-detail-modal-form`}`}
        >
          {type === 'list' && ListData.length > 0 && (
            <div className="detail-list">
              <DHRTable
                locale={providerLocale}
                columns={DETAIL_TYPE_LIST[detialContent?.situationCode]}
                dataSource={ListData}
                sticky={true}
                pagination={{
                  pageSize: 10,
                  total: ListData.length,
                }}
                scroll={{
                  x: 826,
                  y: 436,
                }}
              />
            </div>
          )}
          {type === 'form' && fromData.length > 0 && (
            <div
              className={
                detialContent?.situationCode === 'anniversary'
                  ? 'anniversary-detail-form'
                  : 'detail-form'
              }
            >
              {detialContent?.situationCode === 'promotion' && (
                <div className="detail-form-warp-promotion">
                  <div className="detail-form-warp">
                    <div>晋升生效时间：</div>
                    <div>{getDateShow(fromData[0].effectDate)}</div>
                  </div>
                  {fromData.map(v => {
                    return (
                      <div>
                        <div className="detail-form-warp">
                          <div>晋升前后职级：</div>
                          <div>
                            <span>{v?.beforeStatus || '-'}</span>
                            <ArrowDotOutlined />
                            <span style={{ color: '#0A70F5', fontWeight: '500' }}>
                              {v?.afterStatus || '-'}
                            </span>
                          </div>
                        </div>
                        <div className="detail-form-warp">
                          <div>晋升前后岗位：</div>
                          <div>
                            <span>{v?.beforeJobName || '-'}</span>
                            <ArrowDotOutlined />
                            <span style={{ color: '#0A70F5', fontWeight: '500' }}>
                              {v?.afterJobName || '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {detialContent?.situationCode === 'transfer' && (
                <div className="detail-form-warp-transfer">
                  <div className="detail-form-warp">
                    <div>变更时间：</div>
                    <div>{getDateShow(fromData[0].effectDate)}</div>
                  </div>
                  {fromData.map(v => {
                    return (
                      <div className="detail-form-warp">
                        <div>{DETAIL_TYPE_FORM[detialContent.situationCode][v?.transfer]}：</div>
                        <div>
                          <span>
                            {v?.transfer === '1'
                              ? showEndWord(v?.transferBefore)
                              : v?.transferBefore || '-'}
                          </span>
                          <ArrowDotOutlined />
                          <span style={{ color: '#0A70F5', fontWeight: '500' }}>
                            {v?.transfer === '1'
                              ? showEndWord(v?.transferAfter)
                              : v?.transferAfter || '-'}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {detialContent?.situationCode === 'selftransfer' && (
                <div className="detail-form-warp-selftransfer">
                  <div className="detail-form-warp">
                    <div>变更时间：</div>
                    <div>{getDateShow(fromData[0].effectDate)}</div>
                  </div>
                  {fromData.map(v => {
                    return (
                      <div className="detail-form-warp">
                        <div>{DETAIL_TYPE_FORM[detialContent.situationCode][v?.transfer]}：</div>
                        <div>
                          <span>
                            {v?.transfer === '1'
                              ? showEndWord(v?.transferBefore)
                              : v?.transferBefore || '-'}
                            <ArrowDotOutlined />
                          </span>
                          <span style={{ color: '#0A70F5', fontWeight: '500' }}>
                            {v?.transfer === '1'
                              ? showEndWord(v?.transferAfter)
                              : v?.transferAfter || '-'}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {detialContent?.situationCode === 'badge' && (
                <div className="detail-form-warp-special">
                  {fromData.map(v => {
                    return (
                      <div className="detail-form-warp-special-warp">
                        {/* <div className="detail-form-warp">
                      <div><img src={v?.medalIcon} style={{ width: '16px', height: '16px', lineHeight: '16px' }} />：</div>
                      <div>{v?.medalName}</div>
                    </div> */}
                        <div className="detail-form-warp">
                          <div>勋章分类：</div>
                          <div>{v?.medalCategory || '-'}</div>
                        </div>
                        <div className="detail-form-warp">
                          <div>生效时间：</div>
                          <div>{getDateShow(v?.grantTime) || '-'}</div>
                        </div>
                        <div className="detail-form-warp">
                          <div>勋章描述：</div>
                          <div className="detail-form-warp-medalDesc">{v?.medalDesc || '-'}</div>
                        </div>
                        <img src={v?.medalIcon} className="detail-form-warp-special-badge" />
                      </div>
                    );
                  })}
                </div>
              )}
              {detialContent?.situationCode === 'anniversary' && (
                <div className="detail-form-warp-anniversary">
                  <div className="image-wrapper">
                    <img
                      src={detialContent?.detailUrl}
                      className="detail-form-warp-anniversary-img"
                    />
                  </div>
                  {/* <div>{getDateShow(fromData[0]?.anniversaryDate)} 这天是你入职{fromData[0]?.anniversary}周年！</div>
                  <div>感谢你{fromData[0]?.anniversary}年来的辛勤付出，愿我们热爱不减，羁绊长在，</div>
                  <div>终有所为，有所期待！</div>
                  <img src={"//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/anniversaryModal.png"} className='detail-form-warp-anniversary-img' /> */}
                </div>
              )}
              {detialContent?.situationCode === 'birthday' && fromData.length > 0 && (
                <div className="detail-form-warp-birthday">
                  <div>{getDateShow(fromData[0]?.birthday)} 这天你过生日啦 ！</div>
                  <div>感谢你的温暖相伴，愿你在桔厂的工作生活幸福快乐，</div>
                  <div>身体健康，柿柿如意！</div>
                  <img
                    src={
                      '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/birthdayModal.png'
                    }
                    className="detail-form-warp-bithday-img"
                  />
                </div>
              )}
              {!formTypeList.includes(detialContent?.situationCode) && (
                <div className="detail-form-warp-normal">
                  {fromData.map(v => {
                    return (
                      <div className="detail-form-warp">
                        <div>{v?.label}：</div>
                        <div>
                          {leaveTypeList.includes(detialContent?.situationCode) &&
                          v?.label === '假期类型'
                            ? leaveType[v?.value]
                            : v?.label === '入职部门' ||
                                v?.label === '离职部门' ||
                                v?.label === '转正部门'
                              ? showEndWord(v?.value)
                              : v?.value}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {type === 'piece' && pieceData.length > 0 && (
            <div className="detail-piece">
              <div className="detail-piece-warp">
                <div className="detail-form-warp">
                  <div>生效时间：</div>
                  <div>{getDateShow(pieceData[0]?.effectDate)}</div>
                </div>
              </div>
              {pieceData.map((v, i) => (
                <div className="detail-piece-warp" key={i}>
                  <div className="detail-form-warp">
                    <div>任命角色：</div>
                    <div>{v?.positionName}</div>
                  </div>
                  <div className="detail-form-warp">
                    <div>所属部门：</div>
                    <div>{showEndWord(v?.deptNamePath)}</div>
                  </div>
                  {v?.belongDeptLevel && (
                    <div className="detail-form-warp">
                      <div>部门层级：</div>
                      <div>{v?.belongDeptLevel}</div>
                    </div>
                  )}
                  {v?.roleDeptNamePathList && (
                    <div className="detail-form-warp">
                      <div>管辖部门：</div>
                      <div>{v?.roleDeptNamePathList}</div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </Modal>
      )}
    </>
  );
}
export default DetailModal;
