import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from '@didi/ec-base';
import DetailModal from '../DetailModal';
import PageletServices from '../../services';
import '../../style.scoped.less';

const MyAnchor = props => {
  const { data = [], prefixCls, providerLocale, apiHost, newHeaders = {} } = props;
  const [rest, setRest] = useState([]);
  const [topData, setTopData] = useState([]);
  const [open, setOpen] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [detialContent, setDetialContent] = useState({});
  const detailFlagRef = useRef(false);

  useEffect(() => {
    const dataCopy = JSON.parse(JSON.stringify(data));

    const len = dataCopy.length;
    if (len === 0 || data.length === 0) {
      return;
    }
    if (len < 3) {
      const others = {
        isCanClick: false,
        iconUrl: '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/10.svg',
      };
      for (let i = 0; i < 3 - len; i++) {
        dataCopy.unshift(others);
      }
    }
    setTopData(dataCopy.splice(0, 8));
    setRest(dataCopy);
    // 截取前8个数据
    // setRest(dataCopy)
    // setRest([...data, ...data, ...data, ...data]);
  }, [data]);

  const detailModal = e => {
    detailFlagRef.current = true;
    const params =
      e.situationCode === 'badge'
        ? {
            situationCode: e?.situationCode,
            effectDate: e?.effectDate,
            badgeName: e?.content,
          }
        : {
            situationCode: e?.situationCode,
            effectDate: e?.effectDate,
          };
    PageletServices.getDetail(apiHost, params, newHeaders)
      .then(res => {
        if (res?.code === '100200') {
          const data = res?.data;
          if (
            data?.descriptionListMapVO.length > 0 ||
            Object.keys(data?.descriptionMapVO).length > 0
          ) {
            setDetailVisible(true);
            setDetialContent({
              title: e?.content,
              situationCode: e?.situationCode,
              descriptionListMapVO: data?.descriptionListMapVO || [],
              descriptionMapVO: data?.descriptionMapVO || {},
            });
          }
        }
      })
      .catch(error => {
        message.error(error);
      })
      .finally(() => {
        detailFlagRef.current = false;
      });
  };

  const onClick = item => {
    if (detailFlagRef.current || item.isCanClick === false) {
      return;
    }
    detailModal(item);
  };

  const clickOpen = () => {
    setOpen(!open);
  };
  return (
    <div className="pagelet-personal-didi-anchor">
      {Boolean(data.length) && (
        <div
          className={`pagelet-personal-didi-anchor-first ${rest.length && open && 'pagelet-personal-didi-anchor-first-sec'}`}
        >
          {topData.map(item => (
            // <Avatar
            //   size={16}
            //   src={item.iconUrl}
            //   onClick={() => {
            //     onClick(item);
            //   }}
            //   style={{
            //     cursor: item.isCanClick === false ? 'not-allowed' : 'pointer',
            //   }}
            // />
            <Tooltip title={item?.content}>
              <img
                src={item.iconUrl}
                className="pagelet-personal-didi-anchor-first-img"
                onClick={() => {
                  onClick(item);
                }}
                style={{
                  width: '16px',
                  height: '16px',
                  cursor: item.isCanClick === false ? 'not-allowed' : 'pointer',
                }}
              />
            </Tooltip>
          ))}
          {Boolean(rest.length) && (
            <Tooltip title={open ? '收起' : '展开'} placement="top">
              <div
                style={{
                  backgroundImage: `url("//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/${open ? 'close1' : 'open1'}.svg")`,
                }}
                className="pagelet-personal-didi-anchor-first-btn"
                onClick={clickOpen}
              />
            </Tooltip>
          )}
        </div>
      )}
      <div
        className="pagelet-personal-didi-anchor-second"
        style={{
          height: rest.length && open ? 'auto' : '0px',
          border: rest.length && open ? '0.5px solid #fff' : 'none',
          padding: rest.length && open ? (rest.length > 27 ? '0 1px 5px' : '0 4px 0px') : '0',
        }}
      >
        <div
          style={{ maxHeight: open ? '68px' : '0', overflowY: 'scroll' }}
          className="pagelet-personal-didi-anchor-second-inner"
        >
          {rest.map(item => (
            // <Avatar
            //   size={16}
            //   src={item.iconUrl}
            //   onClick={() => {
            //     onClick(item);
            //   }}
            // />
            <Tooltip title={item?.content}>
              <img
                src={item.iconUrl}
                className="pagelet-personal-didi-anchor-first-img"
                onClick={() => {
                  onClick(item);
                }}
                style={{
                  width: '16px',
                  height: '16px',
                  cursor: item.isCanClick === false ? 'not-allowed' : 'pointer',
                }}
              />
            </Tooltip>
          ))}
        </div>
      </div>
      <DetailModal
        prefixCls={prefixCls}
        detailVisible={detailVisible}
        detialContent={detialContent}
        providerLocale={providerLocale}
        onClose={() => {
          setDetailVisible(false);
        }}
      />
    </div>
  );
};

export default MyAnchor;
