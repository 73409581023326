import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import '../../style.scoped.less';

// 写一个函数，是判断当前季节
const getSeason = effectDate => {
  // return 'winter';
  const month = new Date(effectDate).getMonth();
  // month 为 0-11
  if (month >= 1 && month <= 3) {
    return 'spring';
  } else if (month >= 4 && month <= 6) {
    return 'summer';
  } else if (month >= 7 && month <= 9) {
    return 'autumn';
  } else {
    return 'winter';
  }
};

const AllYearPosition = {
  spring: [
    ['20px', '15px'],
    ['14px', '15px'],
  ],
  summer: [
    ['20px', '15px'],
    ['14px', '14px'],
  ],
  autumn: [
    ['20px', '14px'],
    ['14px', '14px'],
  ],
  winter: [
    ['18px', '15px'],
    ['14px', '15px'],
  ],
};

// 计算元素在循环中的位置
const getPositionInCycle = (index, totalLength) => {
  return (totalLength - index - 1) % 6;
};

const getIslandImg = (position = 0, date) => {
  return `//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/${getSeason(date)}0${position + 1}.png`;
};

const AllSignPosition = {
  spring: [
    ['-50%', '-24%'],
    ['-50%', '-7%'],
    ['-50%', '-15%'],
    ['-50%', '-17%'],
    ['-46%', '-20%'],
    ['-50%', '-13%'],
  ],
  summer: [
    ['-50%', '-24%'],
    ['-50%', '-7%'],
    ['-50%', '-15%'],
    ['-50%', '-17%'],
    ['-46%', '-27%'],
    ['-50%', '-17%'],
  ],
  autumn: [
    ['-45%', '-27%'],
    ['-50%', '-10%'],
    ['-50%', '-25%'],
    ['-50%', '-16%'],
    ['-48%', '-24%'],
    ['-50%', '-22%'],
  ],
  winter: [
    ['-50%', '-24%'],
    ['-50%', '-7%'],
    ['-50%', '-15%'],
    ['-50%', '-17%'],
    ['-46%', '-27%'],
    ['-50%', '-13%'],
  ],
};

// 关键节点图标位置
const AllSignpostPosition = {
  spring: [
    ['20%', '24%'],
    ['32%', '18%'],
    ['25%', '20%'],
    ['29%', '16%'],
    ['22%', '17%'],
    ['36%', '16%'],
  ],
  summer: [
    ['20%', '24%'],
    ['32%', '18%'],
    ['23%', '22%'],
    ['29%', '20%'],
    ['22%', '19%'],
    ['36%', '21%'],
  ],
  autumn: [
    ['20%', '24%'],
    ['32%', '18%'],
    ['25%', '21%'],
    ['29%', '16%'],
    ['22%', '24%'],
    ['36%', '20%'],
  ],
  winter: [
    ['20%', '24%'],
    ['32%', '18%'],
    ['25%', '21%'],
    ['29%', '19%'],
    ['22%', '24%'],
    ['36%', '20%'],
  ],
};

const Key = (props = {}) => {
  const { index, data = {}, len, onClick = () => {} } = props;
  const { effectDate, iconUrl, content, situationName, hasNew, isCurrentYearBegin, status } = data;

  const [signPosition, setSignPosition] = useState(['0%', '0%']);
  const [signpostPosition, setSignpostPosition] = useState(['0%', '0%']);
  const [yearPosition, setYearPosition] = useState(['0%', '0%']);

  const position = getPositionInCycle(index, len);
  const showDetail = () => {
    onClick(data);
  };

  useEffect(() => {
    setSignPosition(AllSignPosition[getSeason(effectDate)]?.[position]);
    setYearPosition(AllYearPosition[getSeason(effectDate)]?.[position % 2]);
    setSignpostPosition(AllSignpostPosition[getSeason(effectDate)]);
  }, [effectDate]);

  const getSituationName = name => {
    if (!name) return '';
    if (name.length > 6 && hasNew) {
      return name.slice(0, 7) + '...';
    } else if (name.length > 9) {
      return name.slice(0, 9) + '...';
    }
    return name;
  };

  const getSignImg = (position, effectDate) => {
    const season = getSeason(effectDate);
    return `//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/${season}-${position % 2 ? 'left' : 'right'}-sign.png`;
  };

  return (
    <div
      className={`pagelet-personal-didi-flow-key ${position % 2 === 0 ? 'pagelet-personal-didi-flow-key-right' : 'pagelet-personal-didi-flow-key-left'}`}
      style={{ position: 'relative', height: '156px' }}
      onClick={showDetail}
    >
      <div
        className={`key-${dayjs(effectDate).format('YYYY-MM')}`}
        style={{ position: 'absolute', top: '-40px', height: '1px', backgroundColor: 'red' }}
      ></div>
      {position % 2 == 1 ? (
        <>
          {/* 左侧 */}
          <div className={'pagelet-personal-didi-flow-signpost '}>
            <div
              style={{
                position: 'absolute',
                left: signpostPosition[position]?.[0],
                top: signpostPosition[position]?.[1],
                zIndex: 200,
              }}
            >
              <img
                className="pagelet-personal-didi-content-img"
                src={iconUrl}
                style={{
                  width: '70px',
                  height: '70px',
                }}
              />
              <div className="pagelet-personal-didi-content-desc">
                <div className="pagelet-personal-didi-content-desc-title-wrapper">
                  <span className="pagelet-personal-didi-content-desc-title-text">
                    <span>{getSituationName(content)}</span>
                    {hasNew && <span className="pagelet-personal-didi-content-desc-new">new</span>}
                    <p>{status === 'unBegin' ? '等待解锁' : dayjs(effectDate).format('YYYY-MM')}</p>
                  </span>
                </div>
                {/* <span className="pagelet-personal-didi-content-desc-content">{content}</span> */}
              </div>
              {isCurrentYearBegin && (
                // {true && (
                <div
                  className="pagelet-personal-didi-content-sign"
                  style={{
                    left: signPosition[0],
                    top: signPosition[1],
                  }}
                >
                  <img src={getSignImg(position, effectDate)} />
                  <span
                    style={{
                      position: 'absolute',
                      left: yearPosition[0],
                      top: yearPosition[1],
                      color: '#fff',
                      transform: `rotate(${position % 2 === 0 ? 7 : -7}deg)`,
                      fontSize: '11px',
                      fontWeight: '600',
                      fontFamily: 'PingFang SC',
                      letterSpacing: '2px',
                    }}
                  >
                    {dayjs(effectDate).format('YYYY')}
                  </span>
                </div>
              )}
            </div>
            <img
              src={getIslandImg(position, effectDate)}
              style={{ width: '206px', height: '156px' }}
            />
            {index !== len - 1 && (
              <img
                src={
                  '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/step-right.png'
                }
                style={{
                  position: 'relative',
                  width: '90px',
                  height: '52px',
                  left: '40%',
                  top: '90%',
                }}
              />
            )}
          </div>
        </>
      ) : (
        <>
          {/* 右侧 */}
          <div className={'pagelet-personal-didi-flow-signpost'}>
            <div
              style={{
                position: 'absolute',
                // left: '36%',
                // top: '28%',
                left: signpostPosition[position]?.[0],
                top: signpostPosition[position]?.[1],
                zIndex: 200,
              }}
            >
              <img
                src={iconUrl}
                className="pagelet-personal-didi-content-img"
                style={{
                  width: '70px',
                  height: '70px',
                }}
              />
              <div className="pagelet-personal-didi-content-desc">
                <div className="pagelet-personal-didi-content-desc-title-wrapper">
                  <span className="pagelet-personal-didi-content-desc-title-text">
                    <span>{getSituationName(content)}</span>
                    {hasNew && <span className="pagelet-personal-didi-content-desc-new">new</span>}
                    <p>{status === 'unBegin' ? '等待解锁' : dayjs(effectDate).format('YYYY-MM')}</p>
                  </span>
                </div>
                {/* <span className="pagelet-personal-didi-content-desc-content">{content}</span> */}
              </div>
              {isCurrentYearBegin && (
                <div
                  className="pagelet-personal-didi-content-sign"
                  style={{
                    left: signPosition[0],
                    top: signPosition[1],
                  }}
                >
                  <img src={getSignImg(position, effectDate)} />
                  <span
                    style={{
                      position: 'absolute',
                      left: yearPosition[0],
                      top: yearPosition[1],
                      color: '#fff',
                      transform: `rotate(${position % 2 === 0 ? 7 : -7}deg)`,
                      fontSize: '11px',
                      fontWeight: '600',
                      fontFamily: 'PingFang SC',
                      letterSpacing: '2px',
                    }}
                  >
                    {dayjs(effectDate).format('YYYY')}
                  </span>
                </div>
              )}
            </div>
            <img
              src={getIslandImg(position, effectDate)}
              style={{ width: '206px', height: '156px' }}
            />

            {index !== len - 1 && (
              <img
                src={
                  '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/step-left.png'
                }
                style={{
                  position: 'absolute',
                  width: '90px',
                  height: '52px',
                  left: '-4%',
                  top: '97%',
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Key;
