import React, { useEffect, useState, useRef } from 'react';
import { getLocale, ConfigProvider, en_US, zh_CN, Spin, message } from '@didi/ec-base';
import { on } from '@dhr/common';

import { Intl } from '@didi/pagelets-sdk';
import { ListViewOutlined, FileDownloadOutlined, LandOutlined } from '@didi/iconflow/ec';
import { Tooltip } from '@didi/ec-base';
import ScopeFilter from './components/ScopeFilter';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import screenShots from '@didi/screenshots';

import Flow from './components/Flow';
import List from './components/List';
import TimeLine from './components/FlowComponents/Timeline';
import Anchor from './components/FlowComponents/Anchor';

import './style.scoped.less';
import './index.less';
import dayjs from 'dayjs';
// 图片建议手动上传到 gift 使用

const localLocales = manifest.locales || {};

// CDN的形式来使用业务组件
// import { Template } from '@didi/ehr-template';
// import { Loader } from '@didi/pagelets-loader';

const Pagelet = props => {
  const {
    params: { apiHost, locales, headers, auth },
  } = props;
  const baseHeight = 628;
  const { current: lang } = useRef(document.cookie.match(/lang=([a-zA-Z\-]*);/)?.[1] || 'zh-CN');
  const [providerLocale, setProviderLocale] = React.useState({});
  const [data, setData] = useState([]);
  const [currentTab, setCurrentTab] = useState('flow');
  const [timeRangeType, setTimeRangeType] = useState('all');
  const [downLoading, setDownLoading] = useState(false);
  const [initError, setInitError] = useState(false);
  const downLoadingRef = useRef(false);
  const [fullScreen, setFullScreen] = useState(false);

  const [timeRangeTypeList, setTimeRangeTypeList] = useState([]);
  const [timeLine, setTimeLine] = useState([]);
  const [badgeList, setBadgeList] = useState([]);
  const openRef = useRef(false);
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    functionId: auth.id,
    functionName: window.encodeURI(auth.functionName),
    'Content-Type': 'application/json',
  };

  const dealItemList = itemList => {
    const dataCopy = JSON.parse(JSON.stringify(itemList));
    const len = dataCopy.length || 0;
    if (len === 0) {
      return;
    }
    // 少于3个，需要添加两项，如果是一个，以第一个为准，第一个的effectDate设置成第一个effectDate的半年后，第二个把effectDate设置成第一个的一年后
    // 如果是两个，需要添加一项以第一个为准，以第一个为准，把最后一个的effectDate设置成第一个的一年后
    if (len < 3) {
      if (len === 2) {
        dataCopy.unshift({
          effectDate: dayjs(dataCopy[0].effectDate).add(12, 'month').format('YYYY-MM-DD'),
          status: 'unBegin',
          content: '小桔子1周年',
          iconUrl: '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/week.png',
        });
      } else {
        dataCopy.unshift(
          {
            effectDate: dayjs(dataCopy[0].effectDate).add(12, 'month').format('YYYY-MM-DD'),
            status: 'unBegin',
            content: '小桔子1周年',
            iconUrl:
              '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/week.png',
          },
          {
            effectDate: dayjs(dataCopy[0].effectDate).add(6, 'month').format('YYYY-MM-DD'),
            status: 'unBegin',
            iconUrl:
              '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/probation.png',
            content: '小桔子转正',
          },
        );
      }
    }
    setData(dataCopy);
  };

  const handleRequest = async () => {
    await PageletServices.getWaterfall(apiHost, newHeaders)
      .then(res => {
        const { code, data } = res || {};
        if (res && code === '100200' && data) {
          dealItemList(data.itemList);
          const yearList = [{ code: 'all', name: '全部' }];
          data?.years?.map(v => {
            yearList.push({
              code: v,
              name: v + '年',
            });
          });
          setTimeRangeTypeList(yearList);
          setTimeLine(data.hotYears || []);
          setBadgeList(data.badgeList || []);
        } else {
          setInitError(true);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  // 接口请求示例
  useEffect(() => {
    handleRequest();
    if (lang !== 'zh-CN') {
      setProviderLocale(en_US);
    } else {
      setProviderLocale(zh_CN);
    }
  }, []);

  const prefixCls = 'pagelet-personal-didi';

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);

  const download1 = () => {
    if (downLoadingRef.current) return;
    downLoadingRef.current = true;
    setDownLoading(true);
    setTimeout(() => {
      screenShots({
        selector: document.querySelector(`#pagelet-personal-didi-flow-content`),
        type: 'png',
        fileName: `我与滴滴${dayjs().format('YYYYMMDDHH')}`,
        chunkHeight: 10000,
        scale: 1.5,
        // needBase64: true
      })
        .then(() => {
          message.success('下载成功');
        })
        .finally(() => {
          downLoadingRef.current = false;
          setDownLoading(false);
        });
    });
  };
  const onTimeRangeType = v => {
    setTimeRangeType(v);
  };

  const changeViewModel = v => {
    setCurrentTab(v);
    setTimeRangeType('all');
  };

  const openFullScreen = flag => {
    let isOpen = !fullScreen;
    if (flag === 'guide') {
      setFullScreen(false);
      isOpen = false;
    } else {
      setFullScreen(!fullScreen);
    }

    let sibling =
      document.querySelector('.pagelet-personal-didi')?.parentElement?.previousElementSibling;
    while (sibling) {
      const currentSibling = sibling;
      currentSibling.classList.add('fade-out');
      currentSibling.style.opacity = isOpen ? '0' : '1';
      currentSibling.style.display = isOpen ? 'none' : 'block';
      sibling = sibling.previousElementSibling;
    }
    const appbarHeight = document.getElementById('appbar')?.offsetHeight || 0;
    const noticeHeight = document.getElementById('common-notice')?.offsetHeight || 0;
    const restHeight = appbarHeight + noticeHeight + 60;
    const flowContainer = document.querySelector('#dhr-my-grid-container-flow-list');
    if (flowContainer) {
      flowContainer.style.maxHeight = isOpen ? `calc(100vh - ${restHeight}px)` : '513px';
    }
    const container = document.querySelector('#dhr-my-grid-container');
    if (isOpen && container) {
      container.scrollTop = 0;
    }
  };

  useEffect(() => {
    on('welcomeHome:show', type => {
      openFullScreen('guide');
    });
  }, []);

  return (
    <ConfigProvider prefixCls="ant5" locale={providerLocale}>
      <div className={classes}>
        <div className={`${prefixCls}-header`}>
          <div>我与DiDi</div>
          <div className={`${prefixCls}-header-warp`}>
            {data.length > 5 && (
              <Tooltip title={fullScreen ? '收起' : '展开'}>
                <div className="warp-icon" style={{ marginRight: '0px' }}>
                  <img
                    src={`//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi/${fullScreen ? 'close' : 'open'}.svg`}
                    onClick={openFullScreen}
                    style={{ position: 'relative', top: '5px' }}
                  />
                </div>
              </Tooltip>
            )}
            {(currentTab === 'flow' || downLoading) && (
              <>
                {!downLoading ? (
                  <div className="warp-icon" style={{ marginLeft: '12px' }}>
                    <FileDownloadOutlined
                      onClick={download1}
                      color="rgba(0, 0, 0, 0.9)"
                      size={18}
                    />
                  </div>
                ) : (
                  <div className={`${prefixCls}-download-loading`} style={{ marginLeft: '12px' }}>
                    下载中，请耐心等待...
                    <div
                      className="warp-icon"
                      style={{ display: 'inline-block', marginRight: '0px' }}
                    >
                      <Spin
                        style={{ fontSize: '16px', marginTop: '4px' }}
                        className={`${prefixCls}-header-downloading`}
                      />
                    </div>
                  </div>
                )}
                {/* { !downLoading ? <div className="warp-icon">
                服<FileDownloadOutlined onClick={download}  color='rgba(0, 0, 0, 0.9)' size={16}/>
              </div> : <div className={`${prefixCls}-download-loading`}>
                下载中，请耐心等待...<div className="warp-icon" style={{display: 'inline-block', marginRight: '0px'}}>
                  <Spin style={{fontSize: '16px', marginTop: '4px'}} className={`${prefixCls}-header-downloading`} />
              </div>
              </div> } */}
              </>
            )}
            <div className="warp-icon-warp">
              <div
                className="warp-icon"
                onClick={() => changeViewModel('flow')}
                style={{ background: `${currentTab === 'flow' ? 'rgba(255, 100, 0, 0.1)' : ''}` }}
              >
                <LandOutlined
                  color={currentTab === 'flow' ? '#FF6400' : 'rgba(0, 0, 0, 0.72)'}
                  size={18}
                />
              </div>
              <div
                className="warp-icon"
                onClick={() => changeViewModel('list')}
                style={{ background: `${currentTab === 'list' ? 'rgba(255, 100, 0, 0.1)' : ''}` }}
              >
                <ListViewOutlined
                  color={currentTab === 'list' ? '#FF6400' : 'rgba(0, 0, 0, 0.72)'}
                  size={18}
                />
              </div>
            </div>

            {/* <div className="warp-icon">
              <EditOutlined />
            </div> */}
            {timeRangeTypeList.length > 0 && currentTab !== 'flow' && (
              <ScopeFilter
                prefixCls={prefixCls}
                subordinate={timeRangeType}
                list={timeRangeTypeList}
                onScopeChange={onTimeRangeType}
              />
            )}
          </div>
        </div>
        {currentTab === 'flow' && timeLine.length > 0 && (
          <TimeLine timeRangeTypeList={timeLine} data={data} fullScreen={fullScreen} />
        )}
        {currentTab === 'flow' && (
          <Anchor
            data={badgeList}
            prefixCls={prefixCls}
            providerLocale={providerLocale}
            apiHost={apiHost}
            newHeaders={newHeaders}
          />
        )}
        <div
          style={{
            minHeight: '360px',
            // maxHeight: '940px',
            maxHeight: '513px',
            height: 'auto',
            overflow: 'hidden auto',
            position: 'relative',
          }}
          id="dhr-my-grid-container-flow-list"
          className={currentTab === 'flow' ? `${prefixCls}-flow` : ''}
        >
          <div id={`${prefixCls}-flow-content`}>
            {currentTab === 'flow' && !initError && (
              <>
                <div
                  style={{
                    height: currentTab === 'flow' ? 'auto' : 0,
                    background: '#E7F4FF',
                    position: 'relative',
                  }}
                >
                  <div id={`${prefixCls}-content-wrapper-cloud`}></div>

                  <Flow
                    apiHost={apiHost}
                    newHeaders={newHeaders}
                    data={data}
                    prefixCls={prefixCls}
                    baseHeight={baseHeight}
                    year={timeRangeType}
                    timeRangeTypeList={timeRangeTypeList}
                  />
                </div>
              </>
            )}
            {currentTab === 'list' && !initError && (
              <div style={{ height: currentTab === 'list' ? 'auto' : 0 }}>
                <List
                  apiHost={apiHost}
                  newHeaders={newHeaders}
                  prefixCls={prefixCls}
                  year={timeRangeType}
                  providerLocale={providerLocale}
                />
              </div>
            )}
            {initError && (
              <div className={`${prefixCls}-error`}>
                <img
                  src="//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didiError1.png"
                  alt=""
                  srcset=""
                />
                <span>服务不可用，请稍后再试</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Pagelet;
