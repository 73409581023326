import React from 'react';
import { Tooltip } from '@didi/ec-base';
export const showEllipseWord = (str, splitWord = '>', isShowHover = true) => {
  if (!str) {
    return '-'
  }
  const arr = str?.split(splitWord);
  const len = arr.length;
  if (len < 4) return str;
  if (isShowHover) {
    return (
      <Tooltip title={str}>
        {`${arr[0]}${splitWord}${arr[1]}${splitWord}...${splitWord}${arr[len - 1]}`}
      </Tooltip>
    );
  }
  return `${arr[0]}${splitWord}${arr[1]}${splitWord}...${splitWord}${arr[len - 1]}`;
};
export const getJobDesc = (v) => {
  const jobFunctionDesc = v?.jobFunctionDesc?.replace(/\s+/g, '') || '-';
  const jobSubFunctionDesc = v?.jobSubFunctionDesc?.replace(/\s+/g, '') || '-';
  const jobFamilyDesc = v?.jobFamilyDesc?.replace(/\s+/g, '') || '-';
  return `${jobFunctionDesc}${'>' + jobSubFunctionDesc}${'>' + jobFamilyDesc}`
}
// 替换时间
export const getDateShow = (date) => {
  return date ? date.replace(/-/g, ".") : '';
}
export const showEndWord = (str, splitWord = '>', isShowHover = true) => {
  if (!str) {
    return '-'
  }
  const arr = str?.split(splitWord);
  const len = arr.length;
  if (len < 4) return str;
  if (isShowHover) {
    return (
      <Tooltip title={str}>
        {`${arr[0]}${splitWord}${arr[1].substring(0, 3)}...${splitWord}${arr[len - 1]}`}
      </Tooltip>
    );
  }
  return `${arr[0]}${splitWord}${arr[1].substring(0, 3)}...${splitWord}${arr[len - 1]}` || '-';
};
const resultType = {
  '0': '面试已安排',
  '1': '待定',
  '2': '通过',
  '3': '淘汰'
}
const recommendList = [
  {
    title: '候选人姓名', dataIndex: 'applyName', key: 'applyName',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 116,
  },
  {
    title: '岗位名称', dataIndex: 'jobCodeDesc', key: 'jobCodeDesc',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 182,
  },
  {
    title: '岗位所属部门', dataIndex: 'jdDeptNamePathHis', key: 'jdDeptNamePathHis',
    ellipsis: true,
    render: (text, record) => (
      showEllipseWord(text)
    ),
    width: 270,
  },
  {
    title: '推荐时间', dataIndex: 'createTime', key: 'createTime',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text && getDateShow(text)}>
        {text && getDateShow(text)}
      </Tooltip>
    ),
    width: 152,
  },
]
const interviewList = [
  {
    title: '候选人姓名', dataIndex: 'applyName', key: 'applyName',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 116,
  },

  {
    title: '岗位名称', dataIndex: 'jobTitle', key: 'jobTitle',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 182,
  },
  {
    title: '岗位所属部门', dataIndex: 'deptNamePath', key: 'deptNamePath',
    ellipsis: true,
    render: (text, record) => (
      showEllipseWord(text)
    ),
    width: 270,
  },
  {
    title: '面试结果', dataIndex: 'result', key: 'result',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text && resultType[text]}>
        {text && resultType[text]}
      </Tooltip>
    ),
    width: 98,
  },
  {
    title: '面试时间', dataIndex: 'interviewTime', key: 'interviewTime',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text && getDateShow(text)}>
        {text && getDateShow(text)}
      </Tooltip>
    ),
    width: 152,
  },
]
/**
 * 列表取值：
 * 晋升promotion
 * 调动transfer
 * 活水selftransfer
 * 内推recommend
 * 面试官interview
 * 勋章badge
 * 组织任命appoint
 * 表单取值：
 * 入职生效onboarding
 * 二次入职rehire
 * 试用期转正trail
 * 合同续签renewal
 * 离职resign
 * 周年anniversary
 * 生日birthday
 * 产假leave_maternity
 * 陪产假leave_paternity
 * 育儿假leave_birth
 * 婚假leave_marriage
 */
const onboardingFormMapping = new Map([
  ['lastHireDate', '入职时间'],
  ['hireDeptNamePath', '入职部门'],
  ['jobDesc', '入职岗位'],
  ['jobLevelDesc', '入职职级'],
  ['jobDescMontage', '入职序列'],
]);
const trailFormMapping = new Map([
  ['positiveDate', '转正时间'],
  ['deptNamePath', '转正部门'],
  ['jobCodeDesc', '转正岗位'],
  ['jobLevelDesc', '转正职级'],
  ['jobDescMontage', '转正序列'],
]);
const renewalFormMapping = new Map([
  ['companyDesc', '合同主体名称'],
  ['contractBegin', '合同生效时间'],
  ['contPlanEndDt', '合同终止时间'],
  ['renewalCnt', '合同续签次数'],
])
const resignFormMapping = new Map([
  ['terminationDate', '离职时间'],
  ['terminationDeptNamePath', '离职部门'],
  ['jobDesc', '离职岗位'],
  ['jobLevelDesc', '离职职级'],
  ['jobDescMontage', '离职序列'],
])
const anniversaryFormMapping = new Map([
  ['anniversaryDate', '周年日期'],
])
const birthdayFormMapping = new Map([
  ['birthday', '生日日期'],
])
const leaveFormMapping = new Map([
  ['beginDate', '开始时间'],
  ['endDate', '结束时间'],
  ['holidayType', '假期类型'],
])
export const leaveType = {
  '5': '产假',
  '8': '陪产假',
  '4': '婚假',
  '13': '育儿假'
}
const transferForm = {
  '1': '变更部门',
  '2': '变更岗位',
  '3': '变更工作地',
  '4': '变更社保地',
  '5': '变更序列',
  '6': '变更合同主体',
}
const selftransferForm = {
  '1': '变更部门',
  '2': '变更岗位',
  '3': '变更工作地',
  '4': '变更社保地',
  '5': '变更序列',
  '6': '变更合同主体',
}

export const DETAIL_TYPE_LIST = {
  'recommend': recommendList,
  'interview': interviewList,
}
export const DETAIL_TYPE_FORM = {
  'transfer': transferForm,
  'selftransfer': selftransferForm,
}
// 映射
export const DETAIL_TYPE_FORM_MAPPING = {
  'onboarding': onboardingFormMapping,
  'rehire': onboardingFormMapping,
  'trail': trailFormMapping,
  'renewal': renewalFormMapping,
  'resign': resignFormMapping,
  'anniversary': anniversaryFormMapping,
  'birthday': birthdayFormMapping,
  'leave_maternity': leaveFormMapping,
  'leave_paternity': leaveFormMapping,
  'leave_marriage': leaveFormMapping,
  'leave_birth': leaveFormMapping,
}
