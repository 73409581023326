// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagelet-personal-didi .pagelet-personal-didi-download-loading .anticon-loading {
  font-size: 14px !important;
}
.pagelet-personal-didi-timeline .ant-timeline-item-content {
  margin-left: 10px !important;
  min-height: 0px !important;
  inset-block-start: -5px;
}
.pagelet-personal-didi-timeline .ant-timeline-item-head {
  width: 7px;
  height: 7px;
  border: 1px solid rgba(0, 0, 0, 0.32);
  background: white;
}
.pagelet-personal-didi-timeline .ant-timeline-item-tail {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  margin-left: -0.5px;
  margin-top: -4px;
  height: calc(100% - 0px);
}
.pagelet-personal-didi-timeline.ant-timeline .ant-timeline-item {
  padding-bottom: 9px;
  line-height: 12px;
}
.pagelet-personal-didi-anchor .pagelet-personal-didi-anchor-second img {
  margin: 4px 4px;
  cursor: pointer;
}
.pagelet-personal-didi-anchor .pagelet-personal-didi-anchor-first img {
  margin: 0 4px;
}
.pagelet-personal-didi-anchor-second-inner::-webkit-scrollbar {
  display: block !important;
  border-radius: 5px;
  width: 3px;
}
.pagelet-personal-didi-anchor-second-inner::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.12);
}
.pagelet-personal-didi-anchor-second-inner::-webkit-scrollbar-track {
  background-color: transparent;
}
.pagelet-personal-didi-anchor-second-inner::-ms-track {
  background-color: transparent;
  border-color: transparent;
  color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/index.less"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;AACA;EACE,4BAA4B;EAC5B,0BAA0B;EAC1B,uBAAuB;AACzB;AACA;EACE,UAAU;EACV,WAAW;EACX,qCAAqC;EACrC,iBAAiB;AACnB;AACA;EACE,yCAAyC;EACzC,mBAAmB;EACnB,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,+BAA+B;AACjC;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".pagelet-personal-didi .pagelet-personal-didi-download-loading .anticon-loading {\n  font-size: 14px !important;\n}\n.pagelet-personal-didi-timeline .ant-timeline-item-content {\n  margin-left: 10px !important;\n  min-height: 0px !important;\n  inset-block-start: -5px;\n}\n.pagelet-personal-didi-timeline .ant-timeline-item-head {\n  width: 7px;\n  height: 7px;\n  border: 1px solid rgba(0, 0, 0, 0.32);\n  background: white;\n}\n.pagelet-personal-didi-timeline .ant-timeline-item-tail {\n  border-left: 1px solid rgba(0, 0, 0, 0.2);\n  margin-left: -0.5px;\n  margin-top: -4px;\n  height: calc(100% - 0px);\n}\n.pagelet-personal-didi-timeline.ant-timeline .ant-timeline-item {\n  padding-bottom: 9px;\n  line-height: 12px;\n}\n.pagelet-personal-didi-anchor .pagelet-personal-didi-anchor-second img {\n  margin: 4px 4px;\n  cursor: pointer;\n}\n.pagelet-personal-didi-anchor .pagelet-personal-didi-anchor-first img {\n  margin: 0 4px;\n}\n.pagelet-personal-didi-anchor-second-inner::-webkit-scrollbar {\n  display: block !important;\n  border-radius: 5px;\n  width: 3px;\n}\n.pagelet-personal-didi-anchor-second-inner::-webkit-scrollbar-thumb {\n  background: rgba(0, 0, 0, 0.12);\n}\n.pagelet-personal-didi-anchor-second-inner::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n.pagelet-personal-didi-anchor-second-inner::-ms-track {\n  background-color: transparent;\n  border-color: transparent;\n  color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
