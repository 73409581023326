import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

  return useRegisterStyle(`${prefixCls}`, () => [
    {
      [`.${prefixCls}-scope`]: {
        ['.ant5-select']: {
          height: '28px',
          ['.ant5-select-selector']: {
            padding: '3px 6px',
            borderRadius: '8px',
            background: 'rgba(0,0,0,0.04) !important',
            border: 'none',
            boxShadow: 'none !important',
            ['.ant5-select-selection-item']: {
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22px',
              letterSpacing: 0,
              color: 'rgba(0, 0, 0, 0.84)',
            }
          },
          ['.ant5-select-arrow']: {
            right: '6px',
            width: '14px',
            height: '14px',
            color: 'rgb(12, 21, 46)',
          },

          ['.ant5-select-dropdown']: {
            width: 'auto !important',
            padding: '4px',
            borderRadius: '8px',
            background: '#FFFFFF',
            boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
            ['div']: {
              ['div:first-child div']: {
                minWidth: '100px',
              }
            },
            ['.ant5-select-item']: {
              minWidth: '100px',
              fontSize: '14px',
              lineHeight: '22px',
              letterSpacing: 0,
              color: 'rgba(0, 0, 0, 0.84)',
              borderRadius: '4px',
              ['&:hover']: {
                background: 'rgba(0, 0, 0, 0.04)'
              }
            },
            ['.ant5-select-item-option-selected']: {
              color: '#FF6400',
              background: '#FFF7F3',
              ['&:hover']: {
                background: '#FFF7F3'
              }
            }
          },
          ['&:hover ']: {
            ['.ant5-select-selector']: {
              background: 'rgba(0, 0, 0, 0.08)',
              boxShadow: 'none !important',
            },
            ['.ant5-select-arrow']: {
              opacity: '1',
            },
          },
          ['&.ant5-select-focused .ant5-select-selector']: {
            border: 'none',
            boxShadow: 'none !important'
          }
        },
        ['&:hover']: {
          ['.ant5-select-selector']: {
            background: 'rgba(0,0,0, 0.08) !important',
          }
        }
      },
    }
  ])
}

export default useStyle;
